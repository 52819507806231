.modal-background {
  background-color: rgba(0, 0, 0, 0.3);
  width: 100vw;
  height: 100%;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.modal-container {
  background-color: inherit;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  background-color: white;
  border-radius: var(--card-border-rad);
  width: 50vw;
  padding: 2vh;
  display: flex;
  flex-direction: column;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);;
}

.modal-header {
  align-self: start;
  margin: 0;
  margin-bottom: 1vh;
  font-size: 2vmax;
}

.modal-body {
  display: flex;
  align-self: center;
  font-size: 1vmax;
  margin: 0;
  margin-bottom: 1vh;
}

div.modal-footer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

button.modal-footer {
  background-color: var(--bs-primary);
  color: white;
  padding: 1vh;
  border-radius: var(--card-border-rad);
  transition: var(--transition-type);
  font-size: 1vmax;
  z-index: 1; /* to remove weird box which comes from overlapping */
}

button.modal-footer:hover {
  z-index: 1; /* to remove weird box which comes from overlapping */
  box-shadow: 0px 5px 40px 1px rgba(0, 0, 0, 0.2);
}
