button.toggle-btn {
  padding: 0;
  margin: 0;
  border-radius: var(--card-border-rad);
  border-style: solid;
  border-width: 1px;
  font-family: 'JetBrains Mono', monospace;
  font-size: 2vmax;
  padding: 1vh;
  padding-left: 2vh;
  padding-right: 2vh;
  margin: 2vmax;
}

button.toggle-on {
  background-color: var(--bs-primary);
  color: white;
  border-color: var(--bs-primary);
}

button.toggle-off {
  background-color: white;
  color: var(--bs-primary);
  border-color: var(--bs-primary);
}
