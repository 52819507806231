.navbar-container {
    position: sticky;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0 -6px 8px 0 rgba(0, 0, 0, 0.2), 0 -10px 20px 0 rgba(0, 0, 0, 0.5);

    top: 0vh;
    width: 100vw;
    min-height: 30px;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;

    z-index: 10;
    border-bottom-left-radius: 2vw;
    border-bottom-right-radius: 2vw;

    --link-border-rad: 1.5vh;
}

.navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 80%;
    padding: 1vmax;
    min-height: inherit;
}

/* Styling to create badge in top left */
.navbar-badge {
    padding: 1vmin;
    background-color: var(--bs-primary);
    border-radius: var(--card-border-rad);
    color: white;
    width: max-content;
    display: flex;
    flex-direction: row;
}

.badge-font {
    font-family: 'JetBrains Mono', monospace;
    font-weight: 800;
    font-size: 1vw;
    color: var(--bs-light);
}

.badge-item {
    margin: 0vh;
}

.badge-item, .menu-item, .menu-item-big {
    height: 50%;
}

/* Navbar page elements */
.menu-item, .menu-item-big {
    font-size: 1vw;
}

.menu-item-big {
    margin: 1vmax;
    padding: 1vmin;
}

.clickable {
    cursor: pointer;
}

.clicked {
    color: #00000058;
}

/* external link styling */
.external-links {
    display: inherit;
}

.external-links > a:active, a:link, a:visited, a:hover {
    color: white;
    border-radius: var(--link-border-rad);
    text-decoration: none;
}

.github {
    background-color: var(--bs-dark);
}

.linked-in {
    background-color: var(--bs-primary);
}

.contact-me {
    background-color: var(--bs-secondary);
    
}
