.home-container {
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 1vh;
}

.home-content {
  width: 75vw;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;

  --section-heading: 2vw;
  --top-position: 15vh;
}

.introducing-me {
  top: var(--top-position);

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: start;

  font-size: var(--section-heading);

  flex: 1;

  margin: 1vw;
}

.basic-info-container, .basic-info-heading {
  background-color: transparent;
  top: var(--top-position);
  flex: 2;
  margin: 1vw;
  align-items: flex-start;

  display: flex;
  flex-direction: column;

  text-align: start;
}

.basic-info-heading {
  margin-bottom: 0vh;
  font-size: var(--section-heading);
  transition: var(--transition-type);
}

.basic-info, .basic-info-json {
  margin: 1vw;
  background-color: white;
  border-radius: var(--card-border-rad);
  padding: 2vmin;
  width: 100%;
  font-size: 1.25vw;

  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px;
  transition: var(--transition-type);
}

.basic-info > p {
  margin-bottom: 0;
}

.basic-info-hover, .basic-info:hover, .basic-info-json:hover {
  box-shadow: rgba(0, 0, 0, 0.22) 0px 19px 43px;
  transition: var(--transition-type);
}

.basic-info-heading-hover {
  transform: translateX(5vw);
}

.basic-info-hover {
  transform: translateX(5vw);
  background-color: transparent;
}

.basic-info-json {
  padding-right: 0vh;
  text-align: start;
}

.basic-info-red {
  color: var(--bs-red);
}

.basic-info-blue {
  color: var(--bs-primary);
}

.basic-info-green {
  color: var(--bs-green);
}

.basic-info-orange {
  color: var(--bs-orange);
}

.basic-info-purple {
  color: var(--bs-purple);
}
