.misc-container {
  position: relative;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  top: 10vh;
}

.misc-container > h3 {
  font-size: 2vw;
}

.misc-container > p {
  font-size: 1vw;
}
