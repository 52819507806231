.card-carousel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-carousel-img-container {
  background-color: inherit;
  width: 25vw;
  height: 30vw;
  display: flex;
  margin-bottom: 2vh;
  cursor: pointer;
  transition: var(--transition-type);
}

.card-carousel-image {
  width: inherit;
  height: inherit;
  object-fit: cover;
  border-radius: 2vmin;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px; 
  transition: var(--transition-type);
}

div.card-carousel-image {
  position: absolute;
}

img.card-carousel-image {
  position: relative;
}

.card-carousel-image:hover {
  box-shadow: rgba(0, 0, 0, 0.22) 0px 19px 43px;
  transition: var(--transition-type);
}

.card-carousel-image-right, .card-carousel-image-left, .card-carousel-image-right-right, .card-carousel-image-left-left {
  --translate-y: 1vh;
  transform-origin: bottom center;
}

.card-carousel-image-right {
  transform: rotateZ(7deg) translateY(var(--translate-y));
}

.card-carousel-image-left {
  transform: rotateZ(-7deg) translateY(var(--translate-y));
}

.card-carousel-image-right-right {
  transform: rotateZ(14deg) translateY(var(--translate-y));
}

.card-carousel-image-left-left {
  transform: rotateZ(-14deg) translateY(var(--translate-y));
}

.card-carousel-text {
  border-radius: 2vmin;
  background-color: white;
  padding: 2vmin;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px;
  transition: var(--transition-type);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 25vw;

  font-size: 1vw;
}

.card-carousel-text:hover {
  cursor: default;
}

.card-carousel-text-hover {
  box-shadow: rgba(0, 0, 0, 0.22) 0px 19px 43px;
  transition: var(--transition-type);
  transform: translateY(5vh);
}