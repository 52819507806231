/* Card container */
.card-scene {
  background-color: transparent;
  width: 75vw;
  align-items: center;
  perspective: 1000px;
}

/* Normal properties */
.card-container {
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: 2vh;

  transition: all 0.3s ease-in-out;

  --footer-text-size: 1.25vw;
}

/* Flippable card properties */
.flip-card-container {
  position: relative;
  height: 40vmin;
  min-height: 100px;
  background-color: transparent;
  transition: transform 1s;
  transform-origin: center;
  transition-property: all;
  transform-style: preserve-3d;
}

.flip-card-container.is-flipped {
  transform: rotateY(180deg);
}

.flip-card-container.is-flipped.is-mirrored {
  transform: rotateY(-180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.card-item-front, .card-item-front-mirrored, .card-item-back {
  background-color: white;
  border-radius: var(--card-border-rad);
  border: 1px solid #e4e4e4;
  margin: 1vmin;
  display: inherit;
  min-height: 100px;
}

.card-item-front-mirrored {
  flex-direction: row-reverse;
}

.card-item-back {
  transform: rotateY(180deg);
}

/* For 'raising card' effect */
.card-shadow-animation {
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px;
  transition: var(--transition-type);
}

.card-shadow-animation:hover {
  box-shadow: rgba(0, 0, 0, 0.22) 0px 19px 43px;
  cursor: pointer;
}

div.card-div-front {
  width: 100%;
  height: 100%;

  border-radius: var(--card-border-rad);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 2vmin;
}

div.card-div-back {
  width: 100%;
  height: 100%;

  text-align: start;
  border-radius: var(--card-border-rad);

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;

  padding: 1.5%;
}

div.card-div-blurb {
  margin: 0vh;
}

h3.card-div-blurb {
  font-size: 2.5vw;
  font-weight: 700;
}

h4.card-div-blurb {
  font-weight: 300;
  font-size: 1.5vw;
}

h5.card-div-blurb {
  font-weight: 300;
  font-size: var(--footer-text-size);
}

p.card-div-blurb {
  font-size: 1vw;
}

b.card-div-blurb {
  font-weight: 700;
}

img.card-div-front, img.card-div-front-mirrored {
  height: 100%;
  width: auto;
  max-width: 40%;
  background-position: center;
  object-fit: cover;
}

img.object-contain {
  object-fit: contain;
}

img.card-div-front {
  border-top-left-radius: 2vh;
  border-bottom-left-radius: 2vh;
  border-right: 2px solid black;
}

img.card-div-front-mirrored {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-left: 2px solid black;
}


div.card-footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

h5.card-footer {
  justify-self: start;
  font-size: var(--footer-text-size);
  margin: 0;
}

.card-shadow {
  filter: drop-shadow(-6px 8px 0 rgba(0, 0, 0, 0.2), 0 -10px 20px 0 rgba(0, 0, 0, 0.19));
}

div.card-link {
  text-decoration: none;
  display: flex;
  flex-direction: column;
}

a.card-link {
  color: var(--bs-primary);
  transition: var(--transition-type);
}

a.card-link:hover {
  text-shadow: rgba(0, 0, 0, 0.25) -2px 2px 15px;
  z-index: 1;
}
